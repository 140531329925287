var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{on:{"click":function($event){_vm.dialog = true}}},[_vm._v("Item Transfers")]),_c('v-dialog',{attrs:{"persistent":"","width":"1400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Item Transfer Modal")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.search,"prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('h3',[_vm._v("All Items Table")]),_c('v-data-table',{attrs:{"search":_vm.search,"items":_vm.items,"headers":_vm.item_headers},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.open_item_transfer(item)}}},[_c('v-icon',[_vm._v("mdi-swap-horizontal")])],1)]}},{key:"item.item_name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.is_vat
                  ? 'green--text font-weight-bold'
                  : 'orange--text font-weight-bold'},[_vm._v(_vm._s(item.item_name))])]}},{key:"item.is_deleted",fn:function(ref){
                  var item = ref.item;
return [(item.is_deleted)?_c('span',{staticClass:"red--text"},[_vm._v("Deleted")]):_c('span',{staticClass:"green--text"},[_vm._v("Active")])]}}])}),_c('h3',[_vm._v("Item Transfer Table")]),_c('v-text-field',{attrs:{"label":_vm.search,"prepend-icon":"mdi-magnify"},model:{value:(_vm.search2),callback:function ($$v) {_vm.search2=$$v},expression:"search2"}}),_c('v-data-table',{attrs:{"search":_vm.search2,"items":_vm.item_transfers,"headers":_vm.item_transfer_headers},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.delete_item_transfer(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close")]),_c('v-spacer'),_c('p',{staticClass:"caption grey--text"},[_vm._v("itemTransferModal.vue")])],1)],1),(_vm.show_item_transfer_modal)?_c('item-transfer-modal-2',{attrs:{"item":_vm.selected_item},on:{"close":function($event){_vm.show_item_transfer_modal = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }